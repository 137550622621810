// export const authenticatedFetch = (navigate, url, options = {}) => {
//     const token = localStorage.getItem('authToken');
//     return fetch(url, {
//       ...options,
//       headers: {
//         ...options.headers,
//         'Authorization': `Bearer ${token}`,
//       },
//     }).then(response => {
//       if (response.status === 401) {
//         // Clear the token and redirect to login
//         localStorage.removeItem('authToken');
//         navigate('/login');
//         throw new Error('Unauthorized');
//       }
//       return response;
//     });
//   };


export const authenticatedFetch = (navigate, url, options = {}, timeout = 60000) => {
  const token = localStorage.getItem('authToken');
  const controller = new AbortController(); // Create an AbortController
  const signal = controller.signal; // Get the signal from the controller

  // Set up the timeout
  const timeoutId = setTimeout(() => {
    controller.abort(); // Abort the fetch request if the timeout is reached
  }, timeout);

  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
    },
    signal, // Pass the abort signal to the fetch options
  })
    .then(response => {
      clearTimeout(timeoutId); // Clear the timeout on success
      if (response.status === 401) {
        // Clear the token and redirect to login
        localStorage.removeItem('authToken');
        navigate('/login');
        throw new Error('Unauthorized');
      }
      return response;
    })
    .catch(error => {
      clearTimeout(timeoutId); // Clear the timeout in case of error
      if (error.name === 'AbortError') {
        throw new Error('Request timed out');
      }
      throw error;
    });
};
