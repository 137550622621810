import {convertVariants} from '../helpFunctions/convertData'
import {convertIncomingData} from '../helpFunctions/convertData'
import { authenticatedFetch } from './authFetch';

const fetchData = async (navigate, method, setSurveys, setViewMode, setAlertMessage, body = '', surveyData = '', surveys = '') => {
  setViewMode('loading')
  let message = ''
  let url = `${window.REACT_APP_API_BASE_URL}survey`
  if (method === 'GET')
    url += `s/${body ? body : 1}`
  if (method === 'DELETE')
    url += `/${surveyData}`

  const options = {
    method: method, 
    headers: {
      "Content-Type": "application/json",
    },
    ...(body && { body: body })
  }
  console.log("🚀 ~ fetchData ~ options:", options)
  
  let response;
  try {
    response = await authenticatedFetch(navigate, url, options);
  } catch (error) {
    console.error("Error fetching data:", error);
    setAlertMessage({ type: 'error', header: 'Failed to fetch data' });
    setViewMode('alert');
    return;
  }

  // Check if the response status is OK
  if (response.ok) {
    const responseData = await response.json()

    // save the new/updated survey into surveys state
    if (responseData.variants) {
      const surveyVariants = convertVariants(responseData.variants)
      const surveyDataWithVariant = {...surveyData, variants: surveyVariants}

      if (method === 'POST') {
        message = "Survey created successfuly!"
        const surveyDataWithId = {...surveyDataWithVariant, surveyId: 
          responseData.survey_id ? responseData.survey_id : surveys[surveys.length-1].surveyId+1}        
        setSurveys([...surveys, surveyDataWithId])
      } else {
        message = "Survey updated successfuly!"
        const updatedSurveys = surveys.map(survey => {
          if (survey.surveyId === surveyDataWithVariant.surveyId) {
            return surveyDataWithVariant;
          }
          return survey;
        });
        setSurveys(updatedSurveys);
      }    
    }

    //Delete survey
    if (responseData.message === 'Survey deleted successfully') {
      message = "Survey deleted successfully"
      const updatedSurveys = surveys.filter((survey, index) => survey.surveyId !== surveyData);
      setSurveys(updatedSurveys);
    }

    if (responseData.surveys) {
      console.log("Fetch surveys successfuly")
      const newSurveys = convertIncomingData(responseData.surveys)
      console.log(newSurveys)
      setSurveys(newSurveys)
      setViewMode('list')
      return      
    }

    setAlertMessage({type: 'success', header: message})
    setViewMode('alert')

    if (responseData.service_account_email)
      return responseData.service_account_email
    
  } else {
    // If there was an error, log it
    if (method === 'GET')
      message = 'surveys fetch failed'
    else if (method === 'DELETE')
      message = 'survey delete failed'
    else
      message = 'Survey saved failed :-('
    const errorResponse = await response.json()
    console.error('Error:', errorResponse);
    const messageDetail = `
      ${
        errorResponse.detail[0].msg 
          ? 
        `${errorResponse.detail[0].msg}, in ${errorResponse.detail[0].loc}` 
          : 
        errorResponse.detail
      }`
    setAlertMessage({type: 'warning', header: message, msg: messageDetail})
    setViewMode('alert')
  }
}

export default fetchData;