
class Variant {
    constructor({
      variantId = '',
      variantName = '',
      isActive = true
    } = {}) {
      this.variantId = variantId;
      this.variantName = variantName;
      this.isActive = isActive;
    }
  }

export default Variant