const convertOutData = (survey) => {
    return {
        "survey_id": Number(survey.surveyId),
        "survey_name": survey.surveyName,
        "source": survey.source,
        "variant_active_default": survey.variantActiveByDefault,
        "source_config": {
          "source": survey.folder,
          "api_key": survey.apiKey
        },
        "variants": survey.variants.map((variant) => {
          return {
            "variant_id": Number(variant.variantId),
            "variant_name": variant.variantName,
            "is_active": variant.isActive
          }
        }),
        "access": survey.access.map((accessItem) => {
          return {
            "email": accessItem.email,
            "role": accessItem.role
          }
        })
      }
}

export {convertOutData}